import './index.scss'
import Avatar from '../../components/core/avatar'
import { useEffect, useState } from 'react'
import { Calendar } from 'primereact/calendar'
import text from '../../data/text.json'
import moment from 'moment'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import PatientForm from './PatientForm'
import Select from '../../api/select'
import { ProgressSpinner } from 'primereact/progressspinner'
import Patient from '../../api/patient'
import classNames from 'classnames'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../routes'
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog'
import 'moment/locale/az'
import { toast } from 'react-toastify'

const DoctorPatient = () => {
  const lang = 'az'
  moment.locale(lang)
  const { id } = useParams()
  const [pickerTime, setPickerTime] = useState(new Date())
  const [formIsActive, setFormIsActive] = useState(false)
  const [doctors, setDoctors] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [meetings, setMeetings] = useState({})
  const meet = meetings?.meetings?.['09:00']?.[0]
  const doctor = {
    name: meet?.doctor_name,
    surname: meet?.doctor_surname,
    photo: meet?.photo,
    duty_label: meet?.doctor_duty_label
  }
  const [excelLink, setExcelLink] = useState('')
  const navigate = useNavigate()

  const getDayIndex = (date) => new Date(date).getDay()

  let hours = [
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30'
  ]

  const fetchDoctors = async () => {
    const data = await Select.getDoctors()
    setDoctors(data)
  }

  const fetchMeetings = async () => {
    const data = await Patient.getDoctorPatients({
      year: moment(pickerTime).format('YYYY'),
      month: moment(pickerTime).format('M'),
      doctor_id: id
    })
    setMeetings(data)
  }

  const fetchData = async () => {
    setIsFetching(true)
    await Promise.all([fetchDoctors(), fetchMeetings(), getExcel()])
    setIsFetching(false)
  }

  const navigateToDetailPage = id => {
    if (id === 'all') {
      navigate(routes.patients.path)
      return
    }
    navigate(routes.doctor_patients.path.replace(':id', id))
  }

  const deleteMeet = async id => {
    try {
      await Patient.delete(id)
      fetchData()
    } catch (e) {
      console.log('Error')
    }
  }

  const openConfirm = (e, id) => {
    e.stopPropagation()
    confirmDialog({
      target: e.currentTarget,
      message: text[lang].are_you_sure_delete,
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteMeet(id),
      acceptLabel: text[lang].yes,
      rejectLabel: text[lang].no,
      acceptClassName: 'p-button-danger'
    })
  }

  const getFullName = meet => {
    const fullname = meet?.name + ' ' + meet?.surname
    if (fullname?.length > 20) {
      return fullname.substring(0, 20) + '...'
    }
    return fullname
  }

  const getExcel = async () => {
    try {
      const data = await Patient.exportDoctorMeetings({
        year: moment(pickerTime).format('YYYY'),
        month: moment(pickerTime).format('M'),
        doctor_id: id
      })
      setExcelLink(URL.createObjectURL(data))
    } catch (e) {
      console.log('Error')
    }
  }

  const handleClick = (meet, index) => {
    setFormIsActive({
      ...meet,
      date: new Date(meet?.date),
      hour: meet?.hour_value
    })
  }

  useEffect(() => {
    fetchData()
  }, [pickerTime, id])

  return (
    isFetching ? (
      <div className="flex justify-content-center pt-5 w-full">
        <ProgressSpinner/>
      </div>
    ) : (
      <div className="patient-page w-full">
        <Dialog
          header={formIsActive?.id ? text[lang].edit_patient : text[lang].new_patient}
          visible={formIsActive}
          draggable={false}
          onHide={() => setFormIsActive(null)}
          style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
          <PatientForm fetchData={fetchData} modal={formIsActive} doctors={doctors}
                       setModal={setFormIsActive}/>
        </Dialog>
        <ConfirmDialog/>
        <div className="flex flex-column gap-2 md:flex-row md:align-items-center justify-content-between p-5">
          <div className="flex flex-column md:flex-row md:align-items-center gap-2">
            <div className="flex flex-column">
              <label htmlFor="picker">{text[lang].date}</label>
              <Calendar
                locale={lang}
                view="month"
                id="picker"
                dateFormat="mm/yy"
                placeholder={text[lang].date}
                className="w-full"
                value={pickerTime}
                onChange={(e) => setPickerTime(e.value)}/>
            </div>
          </div>
          {doctor && (
            <div className="doctor-info flex align-items-center gap-3">
              <Avatar user={doctor}/>
              <div className="flex flex-column">
                <span className="name">{doctor?.name} {doctor?.surname}</span>
                <span className="duty">{doctor?.duty_label}</span>
              </div>
            </div>
          )}
          <div className="flex flex-column md:flex-row gap-2">
            <Dropdown
              value={Number(id)}
              onChange={e => navigateToDetailPage(e?.value)}
              options={[{
                value: 'all',
                label: text[lang].all_doctors
              }, ...doctors]}
              name="prefix"
              className="md:w-14rem"/>
            <a href={excelLink} download="report.xlsx" className="p-button p-button-outlined flex gap-1">
              <i className="pi pi-print"/>
              <span>{text[lang].print}</span>
            </a>
          </div>
        </div>
        <div className="w-full">
          <div className="calendar-container">
            <div className="calendar">
              <div className="left-bar" id="left-bar">
                {hours.map((item) => (
                  <div className="cell">{item}</div>
                ))}
              </div>
              <div className="right-bar" id="right-bar">
                <div className="calendar-header" style={{ minWidth: `${158 * meetings?.length}px` }}>
                  {Array.from({ length: meetings?.lastDayOfMonth }).map((item, index) => (
                    new Date(pickerTime).getMonth() === new Date().getMonth() ? (index + 1 >= new Date().getDate() && (
                      <div className="calendar-header-cell justify-content-center pl-2"
                           id={`header-${index + 1}`} key={index}>
                        <div className="flex gap-2 align-items-center cursor-pointer">
                          {index + 1} {moment(pickerTime).format('MMMM')}
                        </div>
                      </div>
                    )) : (
                      <div className="calendar-header-cell justify-content-center pl-2"
                           id={`header-${index + 1}`} key={index}>
                        <div className="flex gap-2 align-items-center cursor-pointer">
                          {index + 1} {moment(pickerTime).format('MMMM')}
                        </div>
                      </div>
                    )
                  ))}
                </div>
                <div className="calendar-body">
                  {hours?.map((item, index) => (
                    <div className="body-cell"
                         style={{ minWidth: `${158 * meetings?.doctors?.length}px` }}>
                      {meetings?.meetings?.[item]?.map((meet) => (
                        <div className="body-cell-children" onClick={() => handleClick(meet, index)}>
                          {meet?.id && (
                            <div className={classNames({
                              box: true,
                              blue: meet?.is_regular !== 1,
                              yellow: meet?.is_regular === 1,
                              green: !meet?.created_by
                            })}>
                              {meet?.not_work !== 1 ? (
                                <>
                                  <span>{meet?.hour_label}</span>
                                  <span>{getFullName(meet)}</span>
                                  <span className="status">{meet?.status_label}</span>
                                </>
                              ) : (
                                <>
                                  <span>{meet?.hour_label}</span>
                                  <span>{text[lang].i_am_not_join}</span>
                                </>
                              )}
                              <span className="icons">
                                                                {meet?.can_edit && <i className="pi pi-pencil edit"/>}
                                {meet?.can_delete && <i className="pi pi-trash delete"
                                                        onClick={e => openConfirm(e, meet?.id)}/>}
                                                            </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default DoctorPatient
